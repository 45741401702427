import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import public_routes from './route';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to={'/profile/65e216b57d2ed4b5bcc25821'} />} />

          {public_routes?.map((item,idx)=>(
            <Route key={idx} path={item.path} element={item.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
