
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Home = () => {

  const params = useParams();

  const [user, setUser] = useState({
    _id: '', first_name: '', last_name: '', mobile_number: '', designation: '', company: '', created_at: '', updated_at: '',
    email: '', intro: '', is_individual: false, step_completed: 0, card_type: '', address_line_1: '', address_line_2: '',
    city: '', landmark: '', state: '', pin_code: ''
  });

  const addContactHandle = (id) => {
    // axios.get(`https://bvisionr-api.vercel.app/user/saves/${id}`)
    //   .then((data) => alert(JSON.stringify(data.data.message)))
    //   .catch((err) => alert(err.response.data.message));

    const vCardData = "BEGIN:VCARD\nVERSION:3.0\nFN:${user.first_name} ${user.last_name}\nN:${user.last_name};${user.first_name};;;\nTEL;TYPE=CELL:${user.mobile_number}\nEMAIL;TYPE=INTERNET:${user.email}\nORG:${user.company}\nTITLE:${user.designation}\nEND:VCARD"
      .trim();

    const blob = new Blob([vCardData], { type: 'text/vcard;charset=utf-8' });
    // const a = document.createElement('a');
    // a.href = URL.createObjectURL(blob);

    var url = URL.createObjectURL(blob);
  
    const newLink = document.createElement('a');
    newLink.download = `${user.first_name}_${user.last_name}.vcf`;
    newLink.textContent = user.first_name+" "+user.last_name;
    newLink.href = url;
    
    newLink.click();
    // a.download = `${user.first_name}_${user.last_name}.vcf`;
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(URL.createObjectURL(blob));
  };

  useEffect(() => {
    axios.get(`https://bvisionr-api.vercel.app/user/id/${params.id !== null && params.id !== undefined && params.id !== '' ? params.id : '65e216b57d2ed4b5bcc25821'}`)
      .then((resp) => setUser(resp.data.data))
      .catch((err) => alert('User Not Found!'));
  }, [params.id]);

  function getCardSubs(){
    var sub="";
    if((user.designation+ " at  "+user.company).length <=30){
        sub =user.designation+ " at  "+user.company;
    }else{
        sub = user.designation;
    }
    return sub;
  }

  return (
   <div>
         
        <div class="red_bg" style={{height:"30%"}}>
        <div class="main_card_content">
            <img src="/assets/card_bg.png" class="bg_design" alt='Card Background'/>
            <div class="get_your_card">
                Get Your Card
            </div>
            <div class="card_container">
                <img src="/assets/fcard.png" class="card"  alt='Card Front Side'/>
                <div class="card_name">{user.first_name+ " "+user.last_name}</div>
                <div class="card_desig">{getCardSubs()}</div>
            </div>
            <div class="initial_container">
                <div class="initial_circle">{user.first_name.substring(0,1).toUpperCase()+user.last_name.substring(0,1).toUpperCase()}</div>
            </div>
        </div>
        </div>
        <img src="/assets/red_bg.png" class="red_bg" alt='Red Drape'/>

        <div class="main_content">
        <div class="text user_name">{user.first_name+ " "+user.last_name}</div> 
        <div class="text user_desg">{user.designation}</div>
        <div class="text user_company">{user.company}</div>
        <div class="text save_contact" onClick={addContactHandle}>
            Save Contact
        </div>

        <div class="about_container">
            <div class="headings">
                About
            </div >
            <div class="about_content">{user.intro}</div>
        </div>
        <div class="connect_container">
            <div class="headings">
                Connect
            </div>
            <div class="connect_option_container">
                <div class="connect_option">
                    <img src="/assets/youtube.png" class="connect_icon" alt='Youtube Icon'/>
                    <div class="connect_text">
                        Youtube
                    </div>

                </div>
                <div class="connect_option">
                    <img src="/assets/global.png" class="connect_icon" alt='Website Icon'/>
                    <div class="connect_text">
                        Website
                    </div>
                </div>
                <div class="connect_option">
                    <img src="/assets/location_connect.png" class="connect_icon" alt='Location Icon'/>
                    <div class="connect_text">
                        Location
                    </div>
                </div>
            </div>
        </div>


        <div style={{height:"5px"}}></div>
        
        <div class="socials_container">
            <div class="headings">
                Social Network
            </div >
            <div class="socials_options_container">
                <img src="/assets/social/facebook_icon.png" class="socials" alt='Facebook Icon'/>
                <img src="/assets/social/twitter.png" class="socials" alt='X Icon' style={{scale:"0.8"}}/>
                <img src="/assets/social/linkedin_icon.png" class="socials" alt='LinkedIn Icon'/>
                <img src="/assets/social/instagram_icon.png" class="socials" alt='Instagram Icon'/>
                <img src="/assets/social/whatsapp_icon.png" class="socials" alt="WhatsApp Icon"/>
            </div>
        </div>

        <div style={{height:"5px"}}></div>
        <div class="contact_container">
            <div class="headings">
                Contact Info
            </div>
            <div style={{height:"0.5em"}}></div>
            <div class="contact_options_container">
                <div class="contact">
                    <img src="/assets/call.png" class="contact_icon" alt='Call Icon'/>
                    <div class="contact_text">{user.mobile_number}</div>
                    <img src="/assets/forward.png"  class="contact_icon" alt="Forward arrow" style={{height:"1em",width:"1em",paddingLeft:"5px"}}/>
                </div>
                <div class="contact">
                    <img src="/assets/mail.png" class="contact_icon" alt='Mail Icon'/>
                    <div class="contact_text">{user.email}</div>
                    <img src="/assets/forward.png"  class="contact_icon" alt="Forward arrow" style={{height:"1em",width:"1em",paddingLeft:"5px"}}/>
                </div> 
                <div class="contact">
                    <img src="/assets/global.png" class="contact_icon" alt='Website Icon'/>
                    <div class="contact_text">{"website"}</div>
                    <img src="/assets/forward.png"  class="contact_icon" alt="Forward arrow" style={{height:"1em",width:"1em",paddingLeft:"5px"}}/>
                </div>
                <div class="contact">
                    <img src="/assets/location.png" class="contact_icon" alt='Location Icon'/>
                    <div class="contact_text">
                       {
                    //    user.address_line_1+","+user.address_line_2+","+","+user.landmark+","+
                       user.city+", "+user.state}
                    </div>
                    <img src="/assets/forward.png"  class="contact_icon" alt="Forward arrow" style={{height:"1em",width:"1em",paddingLeft:"5px"}}/>
                </div>
            </div>
        </div>
        </div>
   </div>
  )
}

export default Home;