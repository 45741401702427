
import Home from '../pages/Home';


const public_routes = [
    { path: '/profile/:id', element: <Home /> },
    { path: '/profile', element: <Home /> },
];



export default public_routes;